import React, { useEffect, useRef, useState } from "react";
import { OverlayTrigger, Popover } from "react-bootstrap";
import { formatDateWithoutTime } from "../../../utils/formateDate";
import anime from "animejs/lib/anime.es.js";
import Loader from "react-js-loader";
import { useTranslation } from "react-i18next";
const TreeViewNode = ({
  data,
  setSelectedUserId,
  selectedUserId,
  isLoading,
  setSearchUsername,
  key,
}) => {
  const NoProfile = "/images/user-profile.png";
  const [expanded, setExpanded] = useState(false);
  const [hoveredItemId, setHoveredItemId] = useState([]);
  const [tooltipPosition, setTooltipPosition] = useState({});
  const tooltipRef = useRef(null);
  const treeviewContentRef = useRef(null);
  const { t } = useTranslation();

  const handleItemHover = (itemId, event) => {
    const elementRect = event.currentTarget.getBoundingClientRect();
    const tooltipWidth = 420;
    const tooltipHeight = 260;
    const spaceRight = window.innerWidth - elementRect.left;
    const spaceBelow = window.innerHeight - elementRect.bottom;

    if (spaceBelow < tooltipHeight + 10) {
      setTooltipPosition((prevPosition) => ({
        ...prevPosition,
        top: `${
          window.innerHeight - elementRect.top + -(tooltipHeight + 31)
        }px`,
        // left: `${(spaceRight - 35) > tooltipWidth ? '120px' : ''}`
      }));
    }
    setHoveredItemId(itemId);
  };

  const handleClick = (data) => {
    if (data?.hasChildren) {
      setSelectedUserId(data?.id);
      setExpanded(!expanded);
      setSearchUsername("");
    }
  };

  useEffect(() => {
    if (expanded) {
      anime({
        targets: treeviewContentRef.current,
        translateY: [`${-20}px`, `${0}px`],
        opacity: [0, 1],
        duration: 500,
        easing: "easeInQuad",
      });
    }
  }, [expanded]);

  const popover = (
    <Popover id="popover" >
      <div
        id="treeview_pop"
        ref={tooltipRef}
        style={{
          position: "",
          right: tooltipPosition.right,
          top: tooltipPosition.top,
          left: tooltipPosition.left,
          zIndex: "999",
        }}
      >
        <div className="custom-tooltip" >
          <div className="card">
            <div className="card-img-top">
              <div className="card-img-top_img">
                <img
                  src={
                    hoveredItemId?.tooltipData?.profilePic
                      ? hoveredItemId?.tooltipData?.profilePic
                      : NoProfile
                  }
                  alt="profile"
                />
              </div>
              <h5 className="card-title">
                {hoveredItemId?.tooltipData?.username}
              </h5>
              <p className="card-text">
                {hoveredItemId?.tooltipData?.fullName}
              </p>
            </div>
            <div className="card-body">
              <div className="user_detail_tabl">
                <table >
                  <tbody>
                    {hoveredItemId?.tooltipData?.tableData &&
                      hoveredItemId?.tooltipData?.tableData != undefined &&
                      Object?.entries(
                        hoveredItemId?.tooltipData?.tableData
                      )?.map(([key, value]) => (
                        <tr key={key}>
                          <td>{t(key)}</td>
                          <td>
                            {key === "joinDate" ? formatDateWithoutTime(value) : value}
                          </td>
                        </tr>
                      ))}
                  </tbody>
                </table>
              </div>

              {hoveredItemId?.tooltipData?.rankDetails && (
                <a
                  style={{
                    backgroundColor: "green",
                    color: "white",
                    width: "200px",
                    textAlign: "center",
                  }}
                >
                  {hoveredItemId?.tooltipData?.rankDetails?.name + "%"}
                </a>
              )}
            </div>
          </div>
        </div>
      </div>
    </Popover>
  );

  return (
    <>
      <li>
        {data && data?.children && (
          <span onClick={() => handleClick(data)}>
            {data?.hasChildren ? (
              expanded ? (
                <i className="fa fa-minus-square"></i>
              ) : (
                <i className="fa fa-plus-square"></i>
              )
            ) : null}
            {isLoading && data?.id === selectedUserId ? (
              <>
                <Loader
                  type="bubble-scale"
                  bgColor={"#954cea"}
                  color={"#954cea"}
                  size={25}
                />
              </>
            ) : (
              <>
                <div
                  className="treeview_content_box"
                  onMouseEnter={(e) => handleItemHover(data, e)}
                  onMouseLeave={(e) => handleItemHover([], e)}
                >
                  <div className="treeview_content_box_img">
                    <img
                      src={data?.profilePic ? data?.profilePic : NoProfile}
                      alt=""
                    />
                  </div>
                  <div className="treeview_content_box_usr_dtl">
                    <strong>{data?.title}</strong>
                    <div
                      style={{
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        width: "125px",
                      }}
                    >
                      {data?.fullName}
                    </div>
                  </div>
                  <div className="treeview_content_box_left_level">
                    <strong>{data?.level}</strong>Level
                  </div>
                  <OverlayTrigger
                   trigger={["hover", "focus"]}
                    placement="right"
                    overlay={popover}
                  
                  >
                    <div className="treeview_content_box_left_info">
                      <i className="fa fa-info"></i>
                    </div>
                  </OverlayTrigger>
                </div>
              </>
            )}
          </span>
        )}
        {!data.children && (
          <div className="treeview_content_box">
            <div className="treeview_content_box_img">
              <img src={data.image} alt="" />
            </div>
            <div className="treeview_content_box_usr_dtl">
              <strong>{data.id}</strong>
              <div>{data.name}</div>
            </div>
            <div className="treeview_content_box_left_level">
              <strong>{data.level}</strong>Level
            </div>
            <div className="treeview_content_box_left_info">
              <i className="fa fa-info"></i>
            </div>
          </div>
        )}
        {expanded && data.children && (
          <ul
            ref={treeviewContentRef}
            style={{ transform: "translateY(-20px)", opacity: 0 }}
          >
            {data?.children?.map((child) => (
              <TreeViewNode
                key={child.id}
                data={child}
                setSelectedUserId={setSelectedUserId}
                selectedUserId={selectedUserId}
                isLoading={isLoading}
                setSearchUsername={setSearchUsername}
              />
            ))}
          </ul>
        )}
      </li>
    </>
  );
};

export default TreeViewNode;
